export default function useErrorToaster() {
  const toaster = useToaster()
  const { t } = useI18n()

  const toast = ({
    title,
    message,
  }: { title?: string, message?: string } = {}) => {
    // Clear any previous error toasters
    // Disabled this because it caused problems on the organization signup form
    // toaster.clearAll()
    // Then show the new one
    toaster.show({
      title: title || t('errors.oops'),
      message: message || t('errors.oops'),
      color: 'danger',
      icon: 'lucide:alert-triangle',
      closable: true,
    })
  }

  return { toast }
}
